import React from "react";

const TermsofService: React.FC = () => {
    return (
        <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
            <h1>Terms of Service</h1>

            <section>
                <h2>Introduction</h2>
                <p>
                    Welcome to BrandBoost AI. By accessing or using our services, you agree to be bound by these Terms of Service. Please read them carefully, as they outline your rights and obligations while using our platform. If you do not agree to these terms, please do not use our services.
                </p>
            </section>

            <section>
                <h2>Services Provided</h2>
                <p>
                    BrandBoost AI offers analytics and insights on brand engagement and social media sentiment through artificial intelligence. Our services include calculating influence scores, analyzing social sentiment, and providing engagement metrics for client businesses to optimize their brand impact.
                </p>
            </section>

            <section>
                <h2>User Eligibility</h2>
                <p>
                    To use BrandBoost AI’s services, you must be at least 18 years of age and have the authority to agree to these terms on behalf of your company, if applicable. By using our services, you confirm that you meet these requirements.
                </p>
            </section>

            <section>
                <h2>Account Registration</h2>
                <p>
                    Users are required to register an account to access BrandBoost AI’s services. You are responsible for maintaining the confidentiality of your account credentials and agree to notify us immediately if you suspect any unauthorized access to your account. You are liable for all activities conducted under your account.
                </p>
            </section>

            <section>
                <h2>Use of Services</h2>
                <p>
                    BrandBoost AI grants you a limited, non-exclusive, non-transferable right to access and use our services solely for your business purposes. You agree not to misuse the services or attempt to access areas of our platform you are not authorized to use.
                </p>
            </section>

            <section>
                <h2>Prohibited Conduct</h2>
                <p>When using BrandBoost AI’s services, you agree not to:</p>
                <ul>
                    <li>Attempt to reverse-engineer, copy, or exploit any part of our platform.</li>
                    <li>Use our services in any way that violates applicable laws or regulations.</li>
                    <li>Access or collect data from other users or businesses without proper authorization.</li>
                    <li>Interfere with the functionality of our platform, such as attempting to upload viruses or other harmful software.</li>
                </ul>
            </section>

            <section>
                <h2>Intellectual Property</h2>
                <p>
                    BrandBoost AI and its content, including but not limited to its algorithms, analytics, and branding, are owned exclusively by BrandBoost AI. You may not reproduce, distribute, or otherwise use any part of our intellectual property without explicit written permission.
                </p>
            </section>

            <section>
                <h2>Data Collection and Privacy</h2>
                <p>
                    BrandBoost AI collects and analyzes data to provide meaningful insights on social engagement and sentiment. By using our services, you consent to the collection and use of data as described in our <a href="https://pheonix0360.github.io/Facebook-Api-Test/index2.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. You are responsible for ensuring any data you provide complies with applicable privacy regulations.
                </p>
            </section>

            <section>
                <h2>Payment and Billing</h2>
                <p>
                    BrandBoost AI services may be provided on a subscription or pay-per-use basis. By subscribing or purchasing, you agree to provide accurate payment information and authorize BrandBoost AI to charge your payment method according to the agreed pricing plan. Payments are non-refundable except as required by applicable law or stated in specific terms for particular services.
                </p>
            </section>

            <section>
                <h2>Termination</h2>
                <p>
                    BrandBoost AI reserves the right to suspend or terminate your access to the platform at any time if you violate these terms or if we need to perform maintenance on the platform. You may terminate your account at any time by contacting our support team. Termination does not release you from any outstanding payments owed for services rendered.
                </p>
            </section>

            <section>
                <h2>Limitation of Liability</h2>
                <p>
                    To the maximum extent permitted by law, BrandBoost AI and its affiliates shall not be liable for any indirect, incidental, or consequential damages arising from your use of our services. Our liability is limited to the amount you paid for services in the six months preceding any claim.
                </p>
            </section>

            <section>
                <h2>Governing Law</h2>
                <p>
                    These Terms of Service are governed by the laws of [Your Country/State], without regard to its conflict of law principles. Any disputes arising from these terms will be resolved in the courts of [Your Country/State].
                </p>
            </section>

            <section>
                <h2>Changes to Terms</h2>
                <p>
                    BrandBoost AI may modify these Terms of Service from time to time. We will notify you of significant changes by posting updates on our platform. Your continued use of our services following any updates constitutes acceptance of the revised terms.
                </p>
            </section>

            <section>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions regarding these Terms of Service, please contact us at BrandBoost AI Support.
                </p>
            </section>
        </div>
    );
};

export default TermsofService;
