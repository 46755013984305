import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <button
            onClick={() => loginWithRedirect()}
            style={{
                backgroundColor: "#1778f2", // Blue color for login button
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginLeft: "20px",
            }}
        >
            Continue with Facebook
        </button>
    );
};

export default LoginButton;
