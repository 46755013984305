import React from 'react';
import './AboutSection.css';

const AboutSection: React.FC = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h2>About Us</h2>
        <p>
        BrandAI is a full-stack social media management application designed to maximize brand image, particularly for SMBs. This app includes responsive chatbot integration, social media management tools, and features for enhanced social engagement and brand visibility.
          At BrandBoostAI, we are passionate about helping businesses
          stay on top of their brand reputation. With cutting-edge AI solutions,
          we track and analyze online mentions, ensuring you stay ahead in your industry.
        </p>
        <p>
          Our goal is to provide actionable insights that help grow your business,
          manage your reputation, and ensure customer satisfaction. We leverage advanced
          algorithms and machine learning techniques to deliver real-time data analysis
          and reporting, enabling you to make informed decisions quickly.
        </p>
        <p>
          With a dedicated team of experts, we strive to empower our clients by
          offering personalized support and strategic recommendations tailored to
          their unique needs. Join us on a journey to enhance your brand presence
          and achieve remarkable results.
        </p>
      </div>
      <div className="about-image">
        <img src="/path-to-image.jpg" alt="About Us" />
      </div>
    </div>
  );
};

export default AboutSection;
