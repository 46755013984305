import React from 'react';
import './../styles/Profile.css';

import { useNavigate } from 'react-router-dom';
import FacebookDataDeletionButton from './../authO/FacebookDataDeletionButton';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  
  const goToPrivacyPolicy = () => navigate('/app/PrivacyPolicy');
  const goToTermsServices = () => navigate('/app/TermsofService');

  return (
    <div className="profile-page">
      <h1>User Profile</h1>

      <div className="profile-section">
        <h2>Personal Information</h2>
        <div className="info">
          <p><strong>Email:</strong> user@example.com</p>
          <p><strong>Username:</strong> johndoe123</p>
        </div>
      </div>

      <div className="profile-section">
        <h2>Account Management</h2>
        {/* Facebook data deletion button */}
        <FacebookDataDeletionButton />
      </div>

      <div className="profile-section">
        <p>
          Please review our Privacy Policy, and Terms of Services for information on how we handle your data.
        </p>
        <div className="links">
          <div className="link" onClick={goToPrivacyPolicy}>Privacy Policy</div>
          <div className="link" onClick={goToTermsServices}>Terms of Services</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
