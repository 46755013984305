// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 50px;
  background-color: #f9f9f9;
}

.about-content {
  max-width: 600px;
}

.about-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2a2a72;
}

.about-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555; /* Slightly darker text for readability */
}

.about-image img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/LandingPage/components/AboutSection/AboutSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW,EAAE,yCAAyC;AACxD;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,2CAA2C;AAC7C","sourcesContent":[".about-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 100px 50px;\n  background-color: #f9f9f9;\n}\n\n.about-content {\n  max-width: 600px;\n}\n\n.about-content h2 {\n  font-size: 2.5rem;\n  margin-bottom: 20px;\n  color: #2a2a72;\n}\n\n.about-content p {\n  font-size: 1.2rem;\n  line-height: 1.8;\n  margin-bottom: 20px;\n  color: #555; /* Slightly darker text for readability */\n}\n\n.about-image img {\n  max-width: 100%;\n  border-radius: 10px;\n  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
