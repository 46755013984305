import React, { useState } from 'react';
import './../styles/MainContent.css';
import PostComponent from '../types/Post'; 
import { PostProps } from '../types/Post'; 
import Filter from './Filters';
import { FaFilter } from 'react-icons/fa'; // Importing an icon for the filter

const MainContent: React.FC = () => {
  const [filter, setFilter] = useState({
    category: 'All',
    sentiment: 'All',
    influenceScore: 'All',
    dateRange: 'All Time',
    source: 'All',
  });
  
  const [isFilterOpen, setIsFilterOpen] = useState(true); // State for filter visibility

  const posts: PostProps[] = [
    { author: 'daniellaazl', content: '#Foodie #grilledcheese #obsessed @TimHortons', date: '2024-09-09 05:31 AM', influenceScore: 4, sentiment: 'neutral', source: 'Twitter' },
    { author: 'michelleleise90', content: 'Caved in and bought Tims after 10 days of boycotting...', date: '2024-10-05 04:40 PM', influenceScore: 10, sentiment: 'positive', source: 'Instagram' },
    { author: 'johnDoe', content: 'Tim Hortons has been my go-to breakfast place for years! #breakfast #coffee', date: '2024-10-10 09:15 AM', influenceScore: 8, sentiment: 'positive', source: 'Facebook' },
    { author: 'annaSmith', content: 'Had a bad experience today. The coffee was cold... #disappointed', date: '2024-09-30 11:30 AM', influenceScore: 5, sentiment: 'negative', source: 'Twitter' },
    { author: 'sarahJones', content: 'Tried the new Tims sandwich, not bad but could be better.', date: '2024-10-07 12:15 PM', influenceScore: 6, sentiment: 'neutral', source: 'Instagram' },
  ];

  const filterPosts = (post: PostProps) => {
    if (filter.sentiment !== 'All' && post.sentiment !== filter.sentiment) return false;

    if (filter.influenceScore !== 'All') {
      const [minScore, maxScore] = filter.influenceScore.split('-').map(Number);
      if (post.influenceScore < minScore || post.influenceScore > maxScore) return false;
    }

    if (filter.source !== 'All' && post.source !== filter.source) return false;

    return true;
  };

  return (
    <main className="main-content">
      <div className="posts-container" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)', paddingRight: '20px' }}>
        {posts.filter(filterPosts).map((post, index) => (
          <PostComponent
            key={index}
            author={post.author}
            content={post.content}
            date={post.date}
            influenceScore={post.influenceScore}
            sentiment={post.sentiment}
            source={post.source}
          />
        ))}
      </div>
      <div className={`filter-container ${isFilterOpen ? 'open' : 'collapsed'}`}>
        <div className="filter-toggle" onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <FaFilter /> {/* Filter icon */}
          <span>{isFilterOpen ? 'Hide Filters' : 'Show Filters'}</span>
        </div>
        {isFilterOpen && <Filter setFilter={setFilter} />}
      </div>
    </main>
  );
};

export default MainContent;
