import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CTASection.css';

const CTASection: React.FC = () => {
  const navigate = useNavigate();

  const handleEnterDashboard = () => {
    navigate('/app'); // Route to the dashboard, which is wrapped by AuthProviderWrapper
  };

  return (
    <div className="cta-container">
      <h2>Ready to Boost Your Brand?</h2>
      <p>
        Start monitoring your brand today and gain real-time insights into what your customers are saying.
        Our platform is designed to help you identify trends, manage crises, and harness feedback to improve your offerings.
      </p>
      <p>
        Join thousands of satisfied clients who have transformed their brand strategy with our intuitive tools and expert support.
      </p>
      <div className="cta-buttons">
        <button className="btn-primary" onClick={handleEnterDashboard}>Enter Dashboard</button>
        <button className="btn-secondary">Contact Us</button>
      </div>
    </div>
  );
};

export default CTASection;
