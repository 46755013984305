// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-container {
  padding: 100px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.features-container h2 {
  margin-bottom: 50px;
  font-size: 2.5rem;
  color: #2a2a72; /* Title color */
}

.features-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  max-width: 300px;
  text-align: center;
  margin: 10px; /* Margin for spacing */
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-icon {
  font-size: 3rem;
  color: #00aaff;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/LandingPage/components/FeaturesSection/FeaturesSection.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,cAAc,EAAE,gBAAgB;AAClC;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,2CAA2C;EAC3C,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;EAClB,YAAY,EAAE,uBAAuB;AACvC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".features-container {\n  padding: 100px 0;\n  background-color: #f9f9f9;\n  text-align: center;\n}\n\n.features-container h2 {\n  margin-bottom: 50px;\n  font-size: 2.5rem;\n  color: #2a2a72; /* Title color */\n}\n\n.features-grid {\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n}\n\n.feature-card {\n  background: white;\n  border-radius: 10px;\n  padding: 30px;\n  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);\n  transition: transform 0.3s ease;\n  max-width: 300px;\n  text-align: center;\n  margin: 10px; /* Margin for spacing */\n}\n\n.feature-card:hover {\n  transform: translateY(-10px);\n}\n\n.feature-icon {\n  font-size: 3rem;\n  color: #00aaff;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
