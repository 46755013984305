import React, { useState } from 'react';
import './../styles/Filter.css';

type FilterProps = {
  setFilter: (filter: any) => void;
};

const Filter: React.FC<FilterProps> = ({ setFilter }) => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const handleFilterChange = (category: string, value: string) => {
    setFilter((prev: any) => ({ ...prev, [category]: value }));
  };

  const toggleDropdown = (dropdown: string) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <aside className="filter">
      <h2>Filters</h2>

      <div className="filter-item">
        <div className="dropdown">
          <div
            className="dropdown-title"
            onClick={() => toggleDropdown('sentiment')}
          >
            Sentiment
          </div>
          {openDropdown === 'sentiment' && (
            <div className="dropdown-content">
              <div onClick={() => handleFilterChange('sentiment', 'All')}>All</div>
              <div onClick={() => handleFilterChange('sentiment', 'positive')}>Positive</div>
              <div onClick={() => handleFilterChange('sentiment', 'neutral')}>Neutral</div>
              <div onClick={() => handleFilterChange('sentiment', 'negative')}>Negative</div>
            </div>
          )}
        </div>
      </div>

      <div className="filter-item">
        <div className="dropdown">
          <div
            className="dropdown-title"
            onClick={() => toggleDropdown('influenceScore')}
          >
            Influence Score
          </div>
          {openDropdown === 'influenceScore' && (
            <div className="dropdown-content">
              <div onClick={() => handleFilterChange('influenceScore', 'All')}>All</div>
              <div onClick={() => handleFilterChange('influenceScore', '0-3')}>0-3</div>
              <div onClick={() => handleFilterChange('influenceScore', '4-7')}>4-7</div>
              <div onClick={() => handleFilterChange('influenceScore', '8-10')}>8-10</div>
            </div>
          )}
        </div>
      </div>

      <div className="filter-item">
        <div className="dropdown">
          <div
            className="dropdown-title"
            onClick={() => toggleDropdown('source')}
          >
            Source
          </div>
          {openDropdown === 'source' && (
            <div className="dropdown-content">
              <div onClick={() => handleFilterChange('source', 'All')}>All</div>
              <div onClick={() => handleFilterChange('source', 'Twitter')}>Twitter</div>
              <div onClick={() => handleFilterChange('source', 'Instagram')}>Instagram</div>
              <div onClick={() => handleFilterChange('source', 'Facebook')}>Facebook</div>
            </div>
          )}
        </div>
      </div>
    </aside>
  );
};

export default Filter;
