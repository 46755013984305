import React from 'react';
import { FaUserCircle, FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa'; // Import social media icons
import './../styles/Post.css'; // Importing the new CSS for Post

export type PostProps = {
  author: string;
  content: string;
  date: string;
  influenceScore: number;
  sentiment: 'positive' | 'neutral' | 'negative';
  source: string; // Add source property
};

const Post: React.FC<PostProps> = ({ author, content, date, influenceScore, sentiment, source }) => {
  // Map source to corresponding icon
  const renderSourceIcon = () => {
    switch (source) {
      case 'Twitter':
        return <FaTwitter color="#1DA1F2" />;
      case 'Instagram':
        return <FaInstagram color="#C13584" />;
      case 'Facebook':
        return <FaFacebook color="#4267B2" />;
      default:
        return null;
    }
  };

  return (
    <div className={`post ${sentiment}`}>
      <div className="post-header">
        <div className="author-avatar">
          <FaUserCircle size={40} color="#007bff" /> {/* Use the icon as an avatar */}
        </div>
        <div className="post-details">
          <h3>{author} {renderSourceIcon()}</h3> {/* Display source icon next to author */}
          <p className="post-date">{date}</p>
        </div>
      </div>
      <div className="post-content">
        <p>{content}</p>
      </div>
      <div className="post-info">
        <span>Influence Score: {influenceScore}/10</span>
        <span>Sentiment: {sentiment}</span>
      </div>
    </div>
  );
};

export default Post;
