// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-container {
  padding: 100px 20px; /* Added padding for mobile responsiveness */
  background-color: #ffffff;
  text-align: center;
}

.services-container h2 {
  margin-bottom: 50px;
  font-size: 2.5rem;
  color: #2a2a72;
}

.services-grid {
  display: flex;
  justify-content: center;
  gap: 30px; /* Adjusted for even spacing */
  flex-wrap: wrap;
}

.service-card {
  background: #f9f9f9;
  padding: 40px;
  border-radius: 12px; /* Slightly increased border-radius */
  max-width: 300px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 3rem;
  color: #00aaff;
  margin-bottom: 20px;
}

.service-card h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #2a2a72; /* Added color for headers */
}

.service-card p {
  font-size: 1.1rem;
  color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/LandingPage/components/ServicesSection/ServicesSection.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAE,4CAA4C;EACjE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS,EAAE,8BAA8B;EACzC,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB,EAAE,qCAAqC;EAC1D,gBAAgB;EAChB,2CAA2C;EAC3C,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,cAAc,EAAE,4BAA4B;AAC9C;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".services-container {\n  padding: 100px 20px; /* Added padding for mobile responsiveness */\n  background-color: #ffffff;\n  text-align: center;\n}\n\n.services-container h2 {\n  margin-bottom: 50px;\n  font-size: 2.5rem;\n  color: #2a2a72;\n}\n\n.services-grid {\n  display: flex;\n  justify-content: center;\n  gap: 30px; /* Adjusted for even spacing */\n  flex-wrap: wrap;\n}\n\n.service-card {\n  background: #f9f9f9;\n  padding: 40px;\n  border-radius: 12px; /* Slightly increased border-radius */\n  max-width: 300px;\n  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  transition: transform 0.3s ease;\n}\n\n.service-card:hover {\n  transform: translateY(-10px);\n}\n\n.service-icon {\n  font-size: 3rem;\n  color: #00aaff;\n  margin-bottom: 20px;\n}\n\n.service-card h3 {\n  margin-bottom: 15px;\n  font-size: 1.5rem;\n  color: #2a2a72; /* Added color for headers */\n}\n\n.service-card p {\n  font-size: 1.1rem;\n  color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
