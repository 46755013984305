import React, { useEffect, useState } from 'react';
import Papa from 'papaparse'; // Ensure papaparse is installed
import './DataDisplayPage.css'; // Ensure this file exists

interface ParsedData {
  userid: number;
  age: number;
  dob_day: number;
  dob_year: number;
  dob_month: number;
  gender: string;
}

interface ParseResult {
  data: ParsedData[]; // Specify that data is of type ParsedData[]
  errors: any[];
}

const DataDisplayPage: React.FC = () => {
  const [data, setData] = useState<ParsedData[]>([]);
  const [filteredData, setFilteredData] = useState<ParsedData[]>([]);
  const [filters] = useState({
    name: '',
    ageRange: [0, 100],
    gender: '',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;

  useEffect(() => {
    const csvFilePath = '/dashboard/DummyData/pseudo_data.csv';
    console.log('Parsing CSV from:', csvFilePath);
    Papa.parse(csvFilePath, {
      download: true,
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results: ParseResult) => {
        console.log('Parsed results:', results.data);

        if (results.errors.length) {
          console.error('Parsing errors:', results.errors);
        }

        // Filter out invalid data
        const validData = results.data.filter((item) => 
          item.userid !== undefined && 
          item.age !== undefined && 
          item.dob_day !== undefined && 
          item.dob_month !== undefined && 
          item.dob_year !== undefined && 
          item.gender !== undefined
        );

        console.log('Valid data:', validData); // Log valid data

        const limitedData = validData.slice(0, 50);
        setData(limitedData);
        setFilteredData(limitedData);
      },
    });
  }, []);

  useEffect(() => {
    let filtered = data;

    if (filters.name) {
      filtered = filtered.filter((item) =>
        item.gender?.toLowerCase().includes(filters.name.toLowerCase())
      );
    }

    filtered = filtered.filter(
      (item) => item.age >= filters.ageRange[0] && item.age <= filters.ageRange[1]
    );

    if (filters.gender) {
      filtered = filtered.filter((item) => item.gender === filters.gender);
    }

    setFilteredData(filtered);
  }, [data, filters]);

  const handleRandomize = () => {
    const randomIndex = Math.floor(Math.random() * data.length);
    setFilteredData([data[randomIndex]]);
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < filteredData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const displayedData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <div className="data-page">
      <h1>Data Page</h1>
      <div className="filters">
        {/* Your filter inputs */}
      </div>
      <button onClick={handleRandomize}>Randomize Data</button>
      <div className="data-list">
        {displayedData.map((item) =>
          item ? (
            <div key={`${item.userid}-${item.dob_day}`} className="data-item">
              <p>User ID: {item.userid}</p>
              <p>Age: {item.age}</p>
              <p>Birthday: {item.dob_day}/{item.dob_month}/{item.dob_year}</p>
              <p>Gender: {item.gender}</p>
              {/* Add other fields as needed */}
            </div>
          ) : (
            <div key="fallback" className="data-item">
              <p>Data not available</p>
            </div>
          )
        )}
      </div>

      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 0}>
          Previous
        </button>
        <span>Page {currentPage + 1}</span>
        <button onClick={handleNextPage} disabled={(currentPage + 1) * itemsPerPage >= filteredData.length}>
          Next
        </button>
      </div>
    </div>
  );
};

export default DataDisplayPage;
