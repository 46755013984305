import React from 'react';
import './HeroSection.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const HeroSection: React.FC = () => {
  return (
    <div className="hero-container">
      <h1>Revolutionize Your Business with AI</h1>
      <div className="hero-content">
        <div className="info-section">
          <p>
            Unlock the full potential of your business with our cutting-edge AI solutions designed to elevate your operations and drive growth.
          </p>
          <p>
            Join thousands of satisfied clients who trust us for real-time insights and unparalleled support.
          </p>
        </div>
        <div className="actions-section">
          <div className="hero-buttons">
            <button className="btn-primary">Get Started</button>
            <button className="btn-secondary">Learn More</button>
          </div>
          <div className="social-links">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaFacebookF />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaTwitter />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
