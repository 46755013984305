// src/components/LandingPage/LandingPage.tsx
import React from 'react';
import HeroSection from './components/HeroSection/HeroSection';
import AboutSection from './components/AboutSection/AboutSection';
import FeaturesSection from './components/FeaturesSection/FeaturesSection';
import ServicesSection from './components/ServicesSection/ServicesSection';
import CTASection from './components/CTASection/CTASection';

const LandingPage: React.FC = () => {
    return (
        <div>
        <HeroSection />
        <AboutSection />
        <FeaturesSection />
        <ServicesSection />
        <CTASection />
        </div>
    );
};

export default LandingPage;
