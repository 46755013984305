import React from 'react';

const ChatbotIframe: React.FC = () => {
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src="https://www.chatbase.co/chatbot-iframe/0yjdjzUkFqd0869EzvSLt"
                title="Chatbot"
                style={{ width: '100%', height: '100%', border: 'none' }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
        </div>
    );
};

export default ChatbotIframe;
