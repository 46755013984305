const FB_API_VERSION = "v21.0";
const FB_BASE_URL = `https://graph.facebook.com/${FB_API_VERSION}`;

// Access token for authentication (replace with your actual token)

// change to your current token from: meta for developers graph API
const ACCESS_TOKEN =
  "EAAMtdBliznQBO0N5RjsiXrbBnCBZCc5PBXxgelYCAhhquENchn7svKjBxnstzX6IBK5ZB2ZCVsCgtbIA6MecE57KCaBLBgOap1EUUk8bypHIK08uZCugZAAxuTN0V9tSbBFJe83ZClc6Gr8GS5C1BA7VmmiA6YNYNoV2rflZCbNfxjEACBF6Jcw8MdGabhnqOlgadGTBYy18Roh54UZAQuqxj9ZAd2EgDFuMi65BQmCqVO6ZBz8M4AHAZAC"; // Replace with your actual token

export const fetchFacebookUserInfo = async (userId: string) => {
  try {
    const url = `${FB_BASE_URL}/${userId}?fields=id,name&access_token=${ACCESS_TOKEN}`;

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("User not found or invalid request.");
    }

    const data = await response.json();
    return {
      name: data.name || "N/A",
      id: data.id || "N/A",
    };
  } catch (error) {
    console.error("Error fetching Facebook user info:", error);
    throw error;
  }
};
