// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cta-container {
  background-color: #2a2a72;
  padding: 100px 50px;
  text-align: center;
  color: white;
}

.cta-container h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.cta-container p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.cta-buttons .btn-primary {
  background-color: #00aaff;
  padding: 15px 30px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.cta-buttons .btn-secondary {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.cta-buttons .btn-primary:hover, .btn-secondary:hover {
  transform: scale(1.05);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/LandingPage/components/CTASection/CTASection.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,eAAe;AACjB","sourcesContent":[".cta-container {\n  background-color: #2a2a72;\n  padding: 100px 50px;\n  text-align: center;\n  color: white;\n}\n\n.cta-container h2 {\n  font-size: 3rem;\n  margin-bottom: 20px;\n}\n\n.cta-container p {\n  font-size: 1.5rem;\n  margin-bottom: 40px;\n}\n\n.cta-buttons .btn-primary {\n  background-color: #00aaff;\n  padding: 15px 30px;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  font-size: 1.2rem;\n  margin-right: 10px;\n  transition: all 0.3s ease;\n}\n\n.cta-buttons .btn-secondary {\n  background-color: transparent;\n  color: white;\n  border: 2px solid white;\n  padding: 15px 30px;\n  font-size: 1.2rem;\n  border-radius: 5px;\n  transition: all 0.3s ease;\n}\n\n.cta-buttons .btn-primary:hover, .btn-secondary:hover {\n  transform: scale(1.05);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
