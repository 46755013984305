import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';

// Make sure to replace these values with your Auth0 domain and client ID
const domain = 'dev-f53ex6itzpcx1p2e.us.auth0.com';
const clientId = 'WodAv3rzXYhOcdnJseaavZ4TpCHr7wW7';

const root = createRoot(document.getElementById('root')!);
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    // Removing redirectUri as it may not be supported in your version
  >
    <App />
  </Auth0Provider>
);
