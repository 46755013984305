import React from 'react';
import './FeaturesSection.css';
import { FaBolt, FaRocket, FaHandsHelping } from 'react-icons/fa';

const FeaturesSection: React.FC = () => {
  const features = [
    { icon: <FaBolt />, title: "Fast Integration", description: "Easily integrate with our AI systems to start tracking your brand in minutes." },
    { icon: <FaRocket />, title: "Scalable Solutions", description: "Our solutions grow with your business, from startups to enterprises." },
    { icon: <FaHandsHelping />, title: "24/7 Support", description: "Our dedicated support team is always available to assist you with any inquiries." }
  ];

  return (
    <div className="features-container">
      <h2>Our Features</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
