import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './../styles/Sidebar.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

interface SidebarProps {
  isOpen: boolean; // Added prop to control visibility
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen }) => {
  const [isCollapsed] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <aside className={`sidebar ${isCollapsed ? 'collapsed' : ''} ${isOpen ? 'open' : 'closed'}`}>
      <div className="logo">
        <Link to="/"> {/* Link to the landing page */}
          <h1>BrandAI</h1>
        </Link>
      </div>
      <nav className="nav-links">
        <div className={`filters ${isCollapsed ? 'hidden' : ''}`}>
          <h2>Content</h2>
          <div className="nav-item">
            <Link to="/app/data-page">Data Page</Link> {/* New link to the data page */}
          </div>
          {['Dropdown 1', 'Dropdown 2'].map((title, index) => (
            <div key={index}>
              <div className="nav-item" onClick={() => toggleDropdown(index)}>
                {title}
              </div>
              {openDropdown === index && (
                <div className="dropdown">
                  <div>Option 1</div>
                  <div>Option 2</div>
                  <div>Option 3</div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={`social-media ${isCollapsed ? 'hidden' : ''}`}>
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </nav>
      <footer className="sidebar-footer"></footer>
    </aside>
  );
};

export default Sidebar;
