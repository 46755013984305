import React from 'react';
import { useLocation } from 'react-router-dom';
import './../styles/UserPage.css';

const UserPage: React.FC = () => {
  const location = useLocation();
  const userInfo = location.state?.userInfo;

  if (!userInfo) {
    return <div>Error: User data not found.</div>;
  }

  return (
    <div className="user-page">
      <h1>{userInfo.name}'s Social Media Info</h1>
      <p>User ID: {userInfo.id}</p>
      <p>Friends Count: {userInfo.friendsCount}</p>
      <p>Likes Count: {userInfo.likesCount}</p>
    </div>
  );
};

export default UserPage;
