// src/dashboard/DashboardLayout.tsx
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsofService';
import UserPage from './pages/UserPage';
import Chatbot from './pages/chatbot';
import DataPage from './DummyData/DataDisplayPage';
import './styles/DashboardLayout.css';

const DashboardLayout: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-layout">
            <Navbar />
            <button className="toggle-sidebar-button" onClick={toggleSidebar}>
                {isSidebarOpen ? 'Close Sidebar' : 'Open Sidebar'}
            </button>
            <div className="dashboard-body">
                <Sidebar isOpen={isSidebarOpen} />
                <div className="main-content-container">
                    <Routes>
                        <Route path="" element={<MainContent />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="privacypolicy" element={<PrivacyPolicy />} />
                        <Route path="termsofservice" element={<TermsOfService />} />
                        <Route path="user/:id" element={<UserPage />} />
                        <Route path="chatbot" element={<Chatbot />} />
                        <Route path="data-page" element={<DataPage />} /> 
                    </Routes>
                </div>
            </div>
        </div> 
    );
};

export default DashboardLayout; 
