import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchFacebookUserInfo } from './../authO/facebookApiService'; // Import the service
import LoginButton from "./../authO/LoginButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import icons
import { faSearch, faUserCircle, faComments, faHome } from '@fortawesome/free-solid-svg-icons'; // Add home icon
import './../styles/Navbar.css';

const Navbar: React.FC = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const toggleProfileMenu = () => setIsProfileMenuOpen(!isProfileMenuOpen);

  const goToSettings = () => navigate('/app/settings');
  const goToProfile = () => navigate('/app/profile');
  const goToHome = () => navigate('/'); // Navigate to Home
  const goToChatbot = () => navigate('/app/chatbot'); // Navigate to Chatbot

  const handleSearch = async () => {
    if (searchTerm.trim()) {
      setError(null); // Reset error state before searching
      try {
        const userInfo = await fetchFacebookUserInfo(searchTerm.trim());
        if (userInfo && userInfo.id) {
          navigate(`/app/user/${userInfo.id}`, { state: { userInfo } });
          setSearchTerm(''); // Clear the input after searching
        } else {
          setError('User not found.'); // Set error message if user not found
        }
      } catch (error) {
        setError('There was an error fetching the user.'); // Set error message for API error
      }
    }
  };

  const closeErrorModal = () => setError(null); // Function to close the error modal

  return (
    <nav className="navbar">
      <div className="logo" onClick={goToHome}>
        <FontAwesomeIcon icon={faHome} className="home-icon" /> {/* Home icon */}
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search user..."
          className="search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()} // Allow Enter key to search
        />
        <button className="search-button" onClick={handleSearch}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      <div className="action-container">
        <LoginButton />
        <div className="profile" onClick={toggleProfileMenu}>
          <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />
          {isProfileMenuOpen && (
            <div className="profile-menu">
              <div onClick={goToSettings}>Settings</div>
              <div onClick={goToProfile}>Profile</div>
              <div>Sign Out</div>
            </div>
          )}
        </div>
      </div>
      <button className="chatbot-button" onClick={goToChatbot}>
        <FontAwesomeIcon icon={faComments} />
      </button>

      {error && (
        <div className="error-modal">
          <div className="error-content">
            <span className="close-error" onClick={closeErrorModal}>&times;</span>
            <p>{error}</p>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
