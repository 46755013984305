import React from "react";

const PrivacyPolicy: React.FC = () => {
    return (
        <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <h1>Privacy Policy</h1>

        <section>
            <h2>Introduction</h2>
            <p>
            At BrandBoost AI, your privacy is our priority. We are committed to
            maintaining the confidentiality, integrity, and security of your
            personal information. This policy outlines how we collect, use, and
            share your data within our BrandBoost AI services, which are designed
            to enhance social engagement and brand influence for our clients.
            </p>
        </section>

        <section>
            <h2>Information We Collect</h2>
            <p>
            BrandBoost AI collects data to provide effective insights and boost
            brand engagement for our clients. The data we collect includes:
            </p>
            <ul>
            <li>
                Information you provide, such as contact details and preferences
                when signing up for our services.
            </li>
            <li>
                Data about your interactions on social media platforms, including
                public posts, comments, likes, and shared content.
            </li>
            <li>
                Business performance data, sentiment analysis, and social engagement
                metrics relevant to our clients’ brand presence.
            </li>
            <li>
                Device and network information for performance and security
                purposes.
            </li>
            </ul>
        </section>

        <section>
            <h2>How We Use Information</h2>
            <p>We use the data we collect to:</p>
            <h2>Your Rights</h2>
            <p>You have control over your data. You can:</p>
            <ul>
            <li>
                Access and update your information through your account settings.
            </li>
            <li>
                Request deletion of your data in accordance with applicable laws.
            </li>
            <li>
                Manage your privacy settings to control how your data is used within
                our services.
            </li>
            </ul>
        </section>

        <section>
            <h2>Contact Us</h2>
            <p>
            If you have any questions or concerns about our privacy practices,
            please contact us at BrandBoost AI Privacy Support.
            </p>
        </section>
        </div>
    );
};

export default PrivacyPolicy;
