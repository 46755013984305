// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 250px;
  background-color: #343a40;
  color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 80px;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links ul {
  list-style: none;
  padding: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #f8f9fa;
  transition: background-color 0.3s;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown {
  padding-left: 20px;
}

.hidden {
  display: none;
}

.sidebar-footer {
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.social-icons {
  display: flex;
  gap: 15px;
  color: #007bff;
  font-size: 1.5em;
  transition: transform 0.3s all ease-in-out;
}

.social-icons a {
  transition: transform 0.2s;
}

.social-icons a:hover {
  transform: scale(1.2);
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/styles/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,wCAAwC;EACxC,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,iCAAiC;AACnC;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,cAAc;EACd,gBAAgB;EAChB,0CAA0C;AAC5C;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".sidebar {\n  width: 250px;\n  background-color: #343a40;\n  color: #f8f9fa;\n  padding: 20px;\n  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);\n  transition: width 0.3s ease;\n}\n\n.sidebar.collapsed {\n  width: 80px;\n}\n\n.logo {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.nav-links ul {\n  list-style: none;\n  padding: 0;\n}\n\n.nav-item {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  color: #f8f9fa;\n  transition: background-color 0.3s;\n}\n\n.nav-item:hover {\n  background-color: rgba(255, 255, 255, 0.1);\n}\n\n.dropdown {\n  padding-left: 20px;\n}\n\n.hidden {\n  display: none;\n}\n\n.sidebar-footer {\n  margin-top: auto;\n  display: flex;\n  justify-content: space-around;\n  padding-top: 20px;\n}\n\n.social-icons {\n  display: flex;\n  gap: 15px;\n  color: #007bff;\n  font-size: 1.5em;\n  transition: transform 0.3s all ease-in-out;\n}\n\n.social-icons a {\n  transition: transform 0.2s;\n}\n\n.social-icons a:hover {\n  transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
