import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardLayout from './dashboard/DashboardLayout';
import LandingPage from './LandingPage/LandingPage';

const App: React.FC = () => {
  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Landing Page as default route */}
          <Route path="/" element={<LandingPage />} />

          {/* Main app layout for authenticated users */}
          <Route path="/app/*" element={<DashboardLayout />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
