import React from 'react';
import './ServicesSection.css';
import { FaChartLine, FaShieldAlt, FaHeadset } from 'react-icons/fa';

const ServicesSection: React.FC = () => {
  const services = [
    {
      icon: <FaChartLine />,
      title: 'Brand Monitoring',
      description: 'Track and analyze mentions across the web with real-time insights. Our AI algorithms help you understand your brand\'s impact and sentiment.',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Reputation Management',
      description: 'Manage your brand’s reputation with actionable data and analytics. Get alerts for critical mentions and respond proactively.',
    },
    {
      icon: <FaHeadset />,
      title: '24/7 Customer Support',
      description: 'We’re here to help you anytime, anywhere with dedicated customer support. Our team is ready to assist you with any queries or issues.',
    }
  ];

  return (
    <div className="services-container">
      <h2>Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
