import React from 'react';

const FacebookDataDeletionButton: React.FC = () => {
    const handleDataDeletion = async (): Promise<void> => {
        try {
            // Call your server to initiate the deletion process
            const response = await fetch('/api/facebook-data-deletion', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: 'user-facebook-id' }) // Replace with actual user ID
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data: { url: string } = await response.json();
            console.log('Facebook data deletion confirmation URL:', data.url);
            alert('Data deletion initiated. Facebook will be notified.');
        } catch (error) {
            console.error('Error initiating data deletion:', error);
        }
    };

    return (
        <button onClick={handleDataDeletion}>Delete Facebook Data</button>
    );
};

export default FacebookDataDeletionButton;
