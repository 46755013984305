import React from 'react';
import './../styles/Settings.css';

const Settings: React.FC = () => {
  return (
    <div className="settings-page">
      <h1>Settings</h1>
      <div className="settings-option">
        <label htmlFor="theme">Theme</label>
        <select id="theme" className="settings-select">
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </select>
      </div>
      <div className="settings-option">
        <label htmlFor="notifications">Notifications</label>
        <input type="checkbox" id="notifications" className="settings-checkbox" />
      </div>
      <div className="settings-option">
        <label htmlFor="account">Account Settings</label>
        <button className="manage-account-btn">Manage Account</button>
      </div>
    </div>
  );
};

export default Settings;
